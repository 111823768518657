/* App.css */
#map {
  height: 80vh; /* Adjust height as needed */
  width: 100%;
}

footer {
  height: 10vh; /* Adjust height as needed */
  background: #f1f1f1;
  text-align: center;
  padding: 1em;
}

body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  flex-direction: column;
}
